var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.invoice).length > 0
    ? _c(
        "div",
        [
          _c("invoice-detail", {
            attrs: { invoice: _vm.invoice, mode: _vm.mode }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }